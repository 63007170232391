import React from "react"
import { Link } from "gatsby"

import "./styles.scss"

const Tile = ({link, image, title, description}) => (
    <div className="col-md-4 tile">
        <Link to={link}>
            <img src={image} alt="" />
            <h5>{title}</h5>
            <p>{description}</p>
        </Link>
    </div>
)

export default Tile