import React from "react"

import Layout from "../components/layout"
import Page from "../components/Page/page"
import Tile from "../components/Tile"

class Index extends React.Component {
    render() {
        const { location } = this.props
        return (
            <Layout location={location}>
                <Page id="1" />
                <div className="row">
                    <Tile
                        link="/videos"
                        image="https://c3church-jervisbay.s3.ap-southeast-2.amazonaws.com/misc/tile-videos.jpg"
                        title="Sermon Videos"
                        description="Weekly Sermon Video recordings"
                    />
                    <Tile
                        link="/news"
                        image="https://c3church-jervisbay.s3.ap-southeast-2.amazonaws.com/misc/tile-news.jpg"
                        title="News"
                        description="Information about important dates and events"
                    />
                    <Tile
                        link="/sermons"
                        image="https://c3church-jervisbay.s3.ap-southeast-2.amazonaws.com/misc/tile-sermons.jpg"
                        title="Sermon Audio"
                        description="Weekly Sermon Audio recording and allied notes"
                    />
                </div>
            </Layout>
        )
    }
}

export default Index
